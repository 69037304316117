@tailwind base;
@tailwind components;
@tailwind utilities;

.sv-components-row > .sv-components-column--expandable {
  /* position: relative; */
}

.logo-container {
  position: absolute;
  top: 12%;
  left: 7.5%;
  width: 120px;
}

.sd-progress {
  background: #fff !important;
  height: 15px !important;
  border-radius: 20px !important;
}

.sd-progress__bar {
  padding: 5px;
  background: #35b555 !important;
  border-radius: 20px;
  height: 15px !important;
}

.sd-comment {
  z-index: 9999;
}
#sv-nav-complete .sd-btn {
  background: #35b555 !important;
}

.sd-progress__text,
.sd-root-modern .sd-container-modern__title {
  display: none !important;
}

.sv-components-row {
  width: 90% !important;
  margin: 5% auto;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  background: #0b4021;
  padding: 100px 50px 0 50px;
  border-radius: 47px;
  min-height: 70vh;
}

.sd-body.sd-body--static {
  max-width: none !important;
  position: relative;
}

page_logo {
  position: absolute;
  top: 100px;
}

.sd-body.sd-body--static .sd-body__navigation {
  padding-top: 0 !important;
}

.sd-element--with-frame.sd-question--title-top {
  position: absolute;
  top: 0;
  right: 5%;
  width: 65%;
  border-radius: 20px;
  color: #fff;
  min-height: 75%;
  justify-content: center;
  display: flex;
  padding: 20px 100px;
}

/* first image background */
#sq_101,
#sq_107,
#sq_113,
#sq_119,
#sq_121,
#sq_127,
#sq_133,
#sq_139 {
  background: url("./images/firstQuestion.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

/* second image background */
#sq_103,
#sq_109,
#sq_115,
#sq_123,
#sq_129,
#sq_135 {
  background: url("./images/2ndQuestion.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

/* third image background */
#sq_105,
#sq_111,
#sq_117,
#sq_125,
#sq_131,
#sq_137 {
  background: url("./images/3rdQuestion.png");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
}

#sv-nav-next,
#sv-nav-complete {
  right: 6%;
  position: absolute;
  top: 65%;
}

#sv-nav-prev {
  right: 27%;
  position: absolute;
  top: 65%;
}

.sv-string-viewer.sv-string-viewer--multiline,
.sd-item .sv-string-viewer {
  color: #fff;
}
.sd-element__title .sd-element__num {
  color: #fff !important;
}

.schedule_btn {
  justify-content: space-between;
}

.results_sect {
  min-height: 80vh;
}

.close_popup {
  top: 1%;
  right: 5%;
  display: flex;
  justify-content: center;
  align-items: center;
}

@media screen and (max-width: 368px) {
  .schedule_btn {
    justify-content: center;
  }
}

@media screen and (max-width: 1200px) {
  .sd-element--with-frame.sd-question--title-top {
    width: 55%;
  }

  #sv-nav-next,
  #sv-nav-complete {
    top: 70%;
  }

  #sv-nav-prev {
    top: 70%;
  }
}

@media screen and (min-width: 992px) {
  .sd-question__comment-area {
    width: 100%;
    height: 15%;
    margin: 0 auto;
  }

  .sd-progress {
    width: 58%;
    position: absolute !important;
    right: 10%;
  }

  #sq_137 .sd-title.sd-element__title,
  #sq_117 .sd-title.sd-element__title {
    padding: 9 30px;
  }

  #sq_137,
  #sq_117 {
    padding: 25px;
  }

  #sq_137 fieldset,
  #sq_117 fieldset {
    display: grid;
    grid-template-columns: 1fr 1fr;
  }

  #sq_137 .sd-error .sv-string-viewer,
  #sq_117 .sd-error .sv-string-viewer {
    padding: 20px;
  }
}

@media screen and (min-width: 1020px) and (max-width: 1162px) {
  .schedule_btn {
    justify-content: center;
  }

  .sd-progress {
    width: 49%;
  }

  .logo-container {
    left: 9%;
  }
}

@media screen and (max-width: 992px) {
  .sd-element--with-frame.sd-question--title-top {
    position: static;
    width: 100%;
  }

  .sd-image img {
    display: none !important;
  }

  .sd-body__navigation {
    margin: 0 auto;
  }

  .sd-body__navigation .sd-btn,
  #sv-nav-prev {
    position: static;
    margin: 20px auto;
  }

  #sv-nav-next,
  #sv-nav-complete,
  #sv-nav-prev {
    position: static;
    margin: 0 auto;
  }
  .sd-element--with-frame.sd-question--title-top {
    min-height: 60vh;
  }
  .logo-container {
    width: 120px;
    top: 3%;
    left: 40%;
  }

  .sd-progress {
    margin-top: 30px;
  }
}

@media screen and (max-width: 768px) {
  .sd-element--with-frame.sd-question--title-top {
    padding: 40px;
  }

  .logo-container {
    width: 100px;
    left: 38%;
  }
}

@media screen and (max-width: 550px) {
  .sd-body.sd-body--static {
    min-height: 80vh;
  }
  .sv-components-row {
    padding: 20px;
  }

  .sd-progress {
    margin-top: 50px;
  }

  .logo-container {
    left: 36%;
  }

  .close_popup {
    top: 5px;
    left: 2%;
    width: 10%;
    height: 7%;
    padding: 0;
  }
}
